import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';

// Styles
import styles from './animated-icon.module.scss';

interface Props extends IPlayerProps {
    animationClassContainer?: string;
    animationClassPlayer?: string;
}

export function AnimatedIcon(props: Props) {

    const animationContainerClass = classNames(styles.animationContainer, props.animationClassContainer);
    const animationPlayerClass = classNames(styles.player, props.animationClassPlayer);
    return (
        <div className={animationContainerClass}>
            <Player
                {...props}
                className={animationPlayerClass}
                loop
                autoplay
            />
        </div>
    );
}