import { configure } from 'mobx';
import React, { useEffect, useState } from 'react';
import { BodyLayout } from '@infotrack/infotrackgo.web.core/components';
import { HomeLayout } from '~/components/core/layouts/HomeLayout/HomeLayout';
import HomeCenterPane from '../components/Home/HomeCenterPane/HomeCenterPane';
import { HomePageMainContent } from '~/components/Home/HomePageMainContent/HomePageMainContent';
import { observer } from 'mobx-react-lite';
import { MuiSnackbar } from '~/components/MuiSnackbar/MuiSnackbar';
import { useItgRouterArgs } from '~/framework/hooks/useItgRouterArgs';

declare const Tawk_API: any;

configure({
    enforceActions: 'always'
});

// Note: value is arbitrary.
const WAIT_FOR_TAWKTO_LOAD_INTERVAL = 100;

export default observer(function HomePage() {
    const { help } = useItgRouterArgs();
    const [tawktoToggled, setTawktoToggled] = useState(false);
    const [waitForTawktoLoadCount, setWaitForTawktoLoadCount] = useState(0);

    useEffect(() => {
        // If the page loaded with the help arg, open tawkto for the user please...
        // We can't setup a notification for tawkto so we need to check in intervals. ~ Ale ;)
        if (process.browser && help && !tawktoToggled) {
            if (typeof Tawk_API?.toggle === 'function') {
            try {
                // In a try catch block because there is a small chance this can run too early...
                if (Tawk_API.isChatHidden()) Tawk_API.toggle();
                setTawktoToggled(true);
            } catch (e) {}
            } else {
            // Wait a second and update the wait count to try again.
            setTimeout(() => setWaitForTawktoLoadCount(waitForTawktoLoadCount + 1), WAIT_FOR_TAWKTO_LOAD_INTERVAL);
            }
        }
    }, [waitForTawktoLoadCount]);

    // InfoTrackGo can help you find legitimate information about people, property, companies and vehicles, in an instance
    return (
    <>
        <HomeLayout
            title="Instant Property, Company, Personal & Vehicle Searching | InfoTrackGO"
            description="Need information on a Property, Company, Person or Vehicle? InfoTrackGO provides instant one-off searches."
            disableSidedrawer={false}
        >
            <BodyLayout
                topContent={<HomeCenterPane shakeSearchbar={true}/>}
                leftContent={<HomePageMainContent />}
                applyStandardLeftContentPadding={true}
                // To balance with the left side.
                rightContentWidth="46%"
                collapseRightContentAtBreakpoint={'lg'}
            />
        </HomeLayout>
        <MuiSnackbar />
    </>
    );
});
